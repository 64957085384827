<template>
  <section>
    <div
      class="d-flex flex-wrap justify-space-around items-center py-5 flex-column flex-sm-row"
    >
      <span
        v-for="{ active, key } in icons"
        :key="key"
        @click="handlerSelectOrderType(key)"
      >
        <div
          :class="`icon-order__container
          rounded-lg
          my-3
          mx-2
          white--text
          text-center
          ${active ? 'lighten-1' : 'darken-2'}
          ${handlerCheckMenu(key) ? 'blue-grey' : 'bg-disabled'}`"
          elevation="20"
        >
          <base-img
            :class="bg"
            cover
            width="100px"
            :src="require(`@/assets/icons/${key}.webp`)"
            :aspect-ratio="1"
            :opacity="1.7"
          ></base-img>

          <h3>
            {{ $t(key) }}
          </h3>
        </div>
        <span class="text-center" v-if="!handlerCheckMenu(key)">
          <h4 style="color: #ff0000">No disponible</h4>
        </span>
      </span>
    </div>

    <v-alert
      text
      :value="showScanQrMessage"
      type="info"
      dense
      border="left"
      class="my-0"
    >
      {{ $t("scanQrIsRequiredForOrderType") }}
    </v-alert>
  </section>
</template>
<script>
import {
  orderTypeName,
  orderTypeStoreActions,
  orderTypeStoreGetters,
} from "@/store/modules/orderType/constNames";
import { axiosInstance } from "@/plugins/axios";
import { menuStoreActions } from "@/store/modules/menuProducts/constNames";
import { settingsDigitalLetterStoreGetters } from "../../../store/modules/settingsDigitalLetter/constNames";

export default {
  name: "SelectOrderType",
  async mounted() {
    const getOrderType = this.getOrderType;
    const tableId = localStorage.getItem("tableId");
    const orderType =
      getOrderType ??
      (tableId ? orderTypeName.LOCAL : orderTypeName.TO_PICK_UP);
    this.handlerSelectOrderType(orderType);
    this.tableId = tableId;
    const globalConfigs = await axiosInstance.get(
      "api/v1/settings/domicile_pickup/",
      {
        headers: {
          Authorization: `Token ${process.env.VUE_APP_TOKEN}`,
          "Content-Type": "application/json",
          Site: process.env.VUE_APP_SITE,
          Client: "app",
        },
      }
    );
    const { block_domicile_order, block_pickup_order } =
      globalConfigs.data.data;
    this.domicile_order = block_domicile_order;
    this.pickup_order = block_pickup_order;
  },
  data() {
    return {
      tableId: null,
      icons: Object.keys(orderTypeName).map((key) => ({
        active: false,
        key,
      })),
      domicile_order: false,
      pickup_order: false,
    };
  },
  computed: {
    getOrderType() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
    },
    orderTypeIsLocal() {
      const orderType = this.getOrderType;
      return orderType === orderTypeName.LOCAL;
    },
    showScanQrMessage() {
      return this.orderTypeIsLocal && !this.tableId;
    },
  },
  methods: {
    handlerSelectOrderType(key) {
      if (this.handlerCheckMenu(key)) {
        this.icons = this.icons.map((icon) => {
          icon.active = icon.key === key;
          return icon;
        });
        this.$store.dispatch(orderTypeStoreActions.SAVE_ORDER_TYPE, {
          name: key,
          preselected: true,
        });
        this.$store.dispatch("cart/updateProductPriceDependencyOrderType", key);
        this.$store.dispatch(
          menuStoreActions.UPDATE_MENU_PRODUCTS_PRICES,
          this.orderTypeIsLocal
        );
      }
    },
    handlerCheckMenu(key) {
      switch (key) {
        case "LOCAL":
          if (
            this.$store.getters[
              settingsDigitalLetterStoreGetters.GET_LOCAL_ORDERS
            ] === true
          ) {
            return true;
          } else {
            return false;
          }
        case "AT_HOME":
          if (this.domicile_order === true) {
            return false;
          } else {
            return true;
          }
        case "TO_PICK_UP":
          if (this.pickup_order === true) {
            return false;
          } else {
            return true;
          }
        default:
          console.log("Opción no reconocida");
          break;
      }
    },
  },
};
</script>

<style lang="sass">

.icon-order__container
  padding: 10px
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  transition: all 0.3s ease
    .icon-order:
      object-fit: cover
      width: 50px
</style>

<style>
.bg-disabled {
  background-color: #ffcdd2;
}
</style>
