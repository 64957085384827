var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"d-flex flex-wrap justify-space-around items-center py-5 flex-column flex-sm-row"},_vm._l((_vm.icons),function({ active, key }){return _c('span',{key:key,on:{"click":function($event){return _vm.handlerSelectOrderType(key)}}},[_c('div',{class:`icon-order__container
        rounded-lg
        my-3
        mx-2
        white--text
        text-center
        ${active ? 'lighten-1' : 'darken-2'}
        ${_vm.handlerCheckMenu(key) ? 'blue-grey' : 'bg-disabled'}`,attrs:{"elevation":"20"}},[_c('base-img',{class:_vm.bg,attrs:{"cover":"","width":"100px","src":require(`@/assets/icons/${key}.webp`),"aspect-ratio":1,"opacity":1.7}}),_c('h3',[_vm._v(" "+_vm._s(_vm.$t(key))+" ")])],1),(!_vm.handlerCheckMenu(key))?_c('span',{staticClass:"text-center"},[_c('h4',{staticStyle:{"color":"#ff0000"}},[_vm._v("No disponible")])]):_vm._e()])}),0),_c('v-alert',{staticClass:"my-0",attrs:{"text":"","value":_vm.showScanQrMessage,"type":"info","dense":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t("scanQrIsRequiredForOrderType"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }